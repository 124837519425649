
.eye-iconOld {
    position: absolute;
    top: 390px;
    right: 50px;
    cursor: pointer;
  }
  

  .eye-iconNew {
    position: absolute;
    top: 475px;
    right: 50px;
    cursor: pointer;
  }
