.container-input{
    margin-left: 7px;
}

.eye-icon {
    position: absolute;
    top: 74.5%;
    right: 27px;
    transform: translateY(-50%);
    cursor: pointer;
  }
